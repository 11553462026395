<template>
    
    <div class="product paddbo">
        <div class="in-top1 banner">
            <div class="img">
                <img src="../imgs/2019112018089827.jpg" alt="">
            </div>
            <div class="img1">
                <img src="../imgs/2019103015205517.jpg" alt="">
            </div>
            <div class=" banner-text">
                <h1>解决方案</h1>
                <p>Solution</p>
            </div>
            <div class=" banner-txt">
                <div class="main">
                    <p>
                        <span>
                            当前位置：
                        </span>
                        <a href="/">首页</a>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <router-link :to="'/solution/category/'+currentSolutionMenu.id">解决方案</router-link>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <a href="javascript:;">{{ currentSolutionMenu.name }}</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="menu">
            <div class="main clearfix">
                <ul class="clearfix scrollx" id="menu" >
                    <li v-for="menu in menuList" :key="menu.id" :class="{on:menu.id==currentSolutionMenu.id}"
                            @click="selectSoluMenu(menu)">
                        <router-link :to="'/solution/category/'+menu.id">{{ menu.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
       
    </div>

</template>
<script>
import axios from 'axios';
export default {

    data(){
        return{

            currentMenuName:'',
            currentMenuId:1,

            menuList:[]
        }
    },
    computed:{
        currentSolutionMenu(){
            return this.$store.state.currentSolutionMenu
        }
    },
    created(){
        axios.get("/solutionCategory/all").then(res => {
            const categorys = res.data.data.items;
            this.menuList = categorys;
        })

        // const categorys = [
        //         {
        //             id:1,
        //             name:'会议音频系统解决方案',
        //         },
        //         {
        //             id:2,
        //             name:'教学音频系统解决方案',
        //         },
        //         {
        //             id:3,
        //             name:'便携扩声系统解决方案',
        //         },
                
        //     ]
        //     this.menuList = categorys;
        
    },
    methods: {
        selectSoluMenu(e){
            this.$store.commit("setSolutionValue",e)
        },
    },
}
</script>
<style scoped>
    ul{
        display: block;
        overflow: hidden;
        white-space:nowrap;
        -webkit-overflow-scrolling : touch;
        overflow-x:scroll;overflow-y:hidden;
            padding-left:0 ;

    }
    li{
        list-style: none;
    display: inline-block;
    width: 240px;
    }
    .scrollx::-webkit-scrollbar{
	display: none;
}

</style>