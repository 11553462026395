<template>
    <div class="solution-con">
        <div class="solution-one " v-for="(item, index) in solutionList" :key="item.id" :class="{solution_two:index%2==1,solu_blue:index%2==1,solu_gray:index%2==0}">
            <div class="main">
                <router-link :to="'/solution/solutionDetail/'+item.id" class="clearfix">
                    <div class="solu-img">
                        <img :src="$host+'/files/'+item.showImg" alt="">
                    </div>
                    <div class="solu-txt">
                        <h3>{{ item.name }}</h3>
                        <p>
                           {{ item.introduction }}
                        </p>
                        <span>查看更多 >></span>
                    </div>
                </router-link>
            </div>
        </div>
        
    </div >
</template>
<script>
import axios from 'axios';
export default{
    data() {
        return {
            solutionList:[
                {
                    id:1,
                    name:'本地视频会议系统',
                    comment:' 本地视频会议系统适合多方通话、本地会议等，采用S-TRACK数字音频处理器，可兼容市场上多种场景的视讯终端，可实现语音、视频互动，本地扩音等需求；采用AFC、AEC、ANC三大算法能够轻松解决会议室扩声中让人头痛的声学反馈、声学回声、声学噪声等问题。音质更加清晰，让会议更加高效。',
                    path:require('../imgs/2019120614208214.jpg')
                },
                {
                    id:2,
                    name:'大中型扩音系统',
                    comment:' 本地视频会议系统适合多方通话、本地会议等，采用S-TRACK数字音频处理器，可兼容市场上多种场景的视讯终端，可实现语音、视频互动，本地扩音等需求；采用AFC、AEC、ANC三大算法能够轻松解决会议室扩声中让人头痛的声学反馈、声学回声、声学噪声等问题。音质更加清晰，让会议更加高效。',
                    path:require('../imgs/2019120415339787.jpg')
                },
                {
                    id:3,
                    name:'手拉手会议系统',
                    comment:' 本地视频会议系统适合多方通话、本地会议等，采用S-TRACK数字音频处理器，可兼容市场上多种场景的视讯终端，可实现语音、视频互动，本地扩音等需求；采用AFC、AEC、ANC三大算法能够轻松解决会议室扩声中让人头痛的声学反馈、声学回声、声学噪声等问题。音质更加清晰，让会议更加高效。',
                    path:require('../imgs/2019111812004340.jpg')
                },
                {
                    id:4,
                    name:'智能语音识别会议系统',
                    comment:' 本地视频会议系统适合多方通话、本地会议等，采用S-TRACK数字音频处理器，可兼容市场上多种场景的视讯终端，可实现语音、视频互动，本地扩音等需求；采用AFC、AEC、ANC三大算法能够轻松解决会议室扩声中让人头痛的声学反馈、声学回声、声学噪声等问题。音质更加清晰，让会议更加高效。',
                    path:require('../imgs/2019111811404213.jpg')
                }
            ]
        }
    },

    
    created() {
        if(this.$route.params.id){
            const data =this.$route.params.id;
            this.getById(data);
        }
    },

    methods: {
        getById(id){
            axios.get("/solution/category2/",{
                params:{
                    id
                }
            }).then(res => {
                const data = res.data.data.items;
                this.solutionList = data;
            })
        }
    },
}
</script>