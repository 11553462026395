<template>
    <div class="download-con" >
        
        <div class="main">
            <div style="margin-bottom: 15px;">
                <el-input placeholder="请输入内容" v-model="filter" class="input-with-select"  @change="handleChange">
                    <el-select v-model="select" slot="prepend" placeholder="请选择">
                        <el-option label="名称" value="1" ></el-option>
                        <el-option label="时间" value="2" ></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="fetchData(1)"></el-button>
                </el-input>
                </div>
            <el-table
                        :data="tableData"
                        :header-cell-style="{background:'#1d65b0',color:'#ffffff',fontWeigth:'normal',height:'90px'}"
                        style="width: 100%;font-size:20px">
                        <el-table-column
                            prop="originalName"
                            label="名称"
                            width="780">
                        </el-table-column>
                        <el-table-column
                            prop="size"
                            label="大小"
                            width="160">
                        </el-table-column>
                        <el-table-column
                            prop="createDate"
                            label="时间"
                            width="160">
                        </el-table-column>
                        <el-table-column
                            prop=""
                            label="下载"
                           align="center" >
                            <template slot-scope="scope">
                                <el-button @click="handleClick(scope.row)" type="text"  >
                                    <i class="el-icon-download" style="font-size:20px"></i>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        style="text-align:center;font-size: 20px;margin-bottom: 50px;"
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size="10"
                        :current-page="tablePage.currentPage"
                        :total="tablePage.totalResult">
                    </el-pagination>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {

            filter: '',
            select: '1',
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
              },
            tableData: []
        }
    },

    created() {
        this.fetchData(1);
    },

    methods:{



        fetchData(val){
            let paramFilter = {
                pageNum:val,
                name:'',
                time:''
            }
            if(this.select==1){
                paramFilter.name =this.filter;
            }else{
                paramFilter.time =this.filter
            }
            axios.get("/attach/findFile/0",
                {
                    params:paramFilter
                }
            ).then(response => {
            
                const data = response.data.data
                this.tableData = data.items.records
                this.tablePage.currentPage = data.items.current
                this.tablePage.pageSize = data.items.size
                this.tablePage.totalResult = data.items.total

            })
        },

        handleClick(e){
            //  axios.get("http://localhost:8081/attach/download/"+45);
            window.open("http://localhost:8081/attach/download/"+e.id, '_blank');
            // window.open("http://47.94.248.118:8081/attach/download/"+e.id, '_blank');
        },
        handleCurrentChange(val){
           this.fetchData(val)
        },
        handleChange(e){
            console.log(e)
        }
    }
}
</script>
<style scoped>
.main >>> .el-pagination{
    font-weight: 400px !important;
}
.main >>> .el-pagination li{
    font-size: 25px;
}
.main >>> tr{
    height: 81px;
}

.main >>> .el-table--fit{
    margin-bottom: 50px;
}

.main >>> .el-select .el-input {
    width: 130px;
  }
  .main >>> .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>