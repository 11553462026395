<template>
  <!-- <b-navbar toggleable="lg" type="dark" variant="info" class="totop">
      <b-navbar-brand href="#" style="margin-left: 20px;">
        <img src="../assets/logo.png" alt="" width="40px">
      </b-navbar-brand>

      

      <b-collapse id="nav-collapse" class="w-50" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#">Link</b-nav-item>
          <b-nav-item href="#" disabled>Disabled</b-nav-item>
        </b-navbar-nav>
        Right aligned nav items
      </b-collapse>
      <b-nav-form class="w-25">
        <b-input-group style="width:70px">
          <template v-slot:append>
            <b-input-group-text><strong class="text-danger">!</strong></b-input-group-text>
          </template>
          <b-form-input></b-form-input>
        </b-input-group>
        <b-form-input size="sm" class="mr-sm-1" placeholder="Search"></b-form-input>
        <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
      </b-nav-form>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    </b-navbar> -->
  <header class="b-p-3 text-bg-dark totop">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center ">
        
        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2  mb-md-0 scrollx" >
          <li>
            <!-- <a href="/#/" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"> -->
          <b-navbar-brand  class="nav-link px-2 text-white" to="/home" style="margin-left: 20px;font-size: 2.5rem;font-family: fantasy;" >
            MASTAR
            <!-- <img src="../assets/images/c4cd065c49ca8fb127e6ac538679c29.png" alt="" > -->
          </b-navbar-brand>
        <!-- </a> -->

          </li>
          <li>
            <!-- <a href="#" class="nav-link px-2 text-white">首页</a> -->
            <b-navbar-brand class="nav-link px-2 text-white" to="/home">首页</b-navbar-brand>
          </li>
          <li>
            <b-navbar-brand class="nav-link px-2 text-white" :to="'/product/category/'+productList[0].id" @click="toSendProductTitle(productList[0])">产品中心</b-navbar-brand>
          </li>
          <li>
            <b-navbar-brand class="nav-link px-2 text-white" :to="'/solution/category/'+solutionList[0].id" @click="toSendSolutionTitle(solutionList[0])">解决方案</b-navbar-brand>
          </li>
          <li>
            <b-navbar-brand class="nav-link px-2 text-white" to="/service/sales" @click="toSendServiceTitle(serviceList[0])">服务支持</b-navbar-brand>
          </li>
          <!-- <li>
            <b-navbar-brand class="nav-link px-2 text-white" to="/news/category/9" @click="toSendSolutionTitle(solutionList[0])">新闻中心</b-navbar-brand>
          </li> -->
          <li>
            <b-navbar-brand class="nav-link px-2 text-white" to="/about/company" @click="toSendAboutTitle(about[0])">关于我们</b-navbar-brand>
          </li>
          <li>  <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
          <input type="search" class="form-control form-control-dark text-bg-dark" placeholder="Search..." aria-label="Search">
        </form></li>
        </ul>
<!-- 
        <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
          <input type="search" class="form-control form-control-dark text-bg-dark" placeholder="Search..." aria-label="Search">
        </form> -->
<!-- 
        <div class="text-end">
          <button type="button" class="btn btn-outline-light me-2">Login</button>
          <button type="button" class="btn btn-warning">Sign-up</button>
        </div> -->
      </div>
    </div>
  </header>
 
</template>

<script>
import axios from 'axios';
import bus from '@/bus.js'
export default {
    data() {
        return {
          productList:[
            // {
            //   id:'25',
            //   name:'数字音频设备'
            // }
          ],
          solutionList:[
            {
              id:'1',
              name:'会议音频系统解决方案'
            }
          ],
          serviceList:[
            {
              id:"1",
              name:'售后服务'
            }
          ],
          newsList:[],
          about:[
            {path:'company'}
          ],
           
        }
    },

    created() {
      this.getProdcutList();
      this.getSolutionList();
      this.getCompany();
    },

    methods: {
      toSendProductTitle(e){
        this.$store.commit("setProductValue",e)
      },
      toSendSolutionTitle(e){
        this.$store.commit("setSolutionValue",e)
      },
      toSendServiceTitle(e){
        this.$store.commit("setServiceValue",e)
      },
      toSendAboutTitle(e){
        this.$store.commit("setAboutValue",e)
      },
      getProdcutList(){
            axios.get("/category/isuse").then(res => {
                if(res.data.data.items.length>0){
                    const categorys = res.data.data.items;
                    this.productList = categorys;
                    this.$store.commit("setProductList",categorys)
                }
                
            })
        },
        getSolutionList(){
            axios.get("/solutionCategory/all").then(res => {
                
                if(res.data.data.items.length>0){
                    const categorys = res.data.data.items;
                    this.solustionList = categorys;
                    this.$store.commit("setSolutionList",categorys)
                }
            })
        },
        getCompany(){
            axios.get("/company/isUse").then(res => {
                if(res.data.success){
                    this.comapny = res.data.data.items;
                    this.$store.commit("setCompany",res.data.data.items)
                }
                    
            })
        }
    }
}    
</script>
<style scoped>
.bg-info{
    background: #333333 !important;
    /* padding: 20px 0; */
}
.totop{
          position: fixed;
          top:0;
          z-index: 999;
          width: 100%;
          padding: 20px 0;
      }
.p-3{
  padding: 1rem!important;
}
.text-bg-dark{
  color: #fff!important;
    background-color: RGBA(33,37,41,var(--bs-bg-opacity,1))!important;
}
ul{
  display:block;
  white-space: nowrap;
  
  
}
ul li {
  padding: 0 20px;
  display: inline-block;
}
.scrollx{
  overflow-y: hidden;
	overflow-x: scroll; 
}
.scrollx::-webkit-scrollbar{
	display: none;
}

@media (max-width:640px){

  ul li{
    padding: 0;
  }
  .totop{
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .container{
    max-width: 1400px;
  }
}
</style>
