<template>
    
    <div class="service paddbo">
        <div class="in-top1 banner">
            <div class="img">
                <img src="../imgs/2022102617543718.jpg" alt="">
            </div>
            <div class="img1">
                <img src="../imgs/2022102617544585.jpg" alt="">
            </div>
            <div class=" banner-text">
                <h1>服务支持</h1>
                <p>Service Support</p>
            </div>
            <div class=" banner-txt">
                <div class="main">
                    <p>
                        <span>
                            当前位置：
                        </span>
                        <a href="/">首页</a>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <router-link to="">服务支持</router-link>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <a href="javascript:;">{{ currentServiceMenu.name }}</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="menu">
            <div class="main clearfix">
                <ul class="clearfix scrollx" id="menu" >
                    <li v-for="menu in menuList" :key="menu.id" :class="{on:menu.id==currentServiceMenu.id}"
                            @click="selectServiceMenu(menu)">
                        <router-link :to="'/service/'+menu.path">{{ menu.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
       
    </div>

</template>
<script>
import axios from 'axios';
export default {

    data(){
        return{
            menuList:[]
        }
    },
    computed:{
        currentServiceMenu(){
          
            return this.$store.state.currentServiceMenu
        }
    },
    created(){
       
        const categorys = [
                {
                    id:1,
                    name:'售后服务',
                    path:'sales'
                },
                {
                    id:2,
                    name:'常见问题',
                    path:'question'
                },
                {
                    id:3,
                    name:'视频中心',
                    path:'video'
                },
                {
                    id:4,
                    name:'资料下载',
                    path:'download'
                },
                
            ]
            this.menuList = categorys;
        
    },

    methods: {
        selectServiceMenu(e){
            this.$store.commit("setServiceValue",e)
        },
    },
    
}
</script>
<style scoped>
    ul{
        display: block;
        overflow: hidden;
        white-space:nowrap;
        -webkit-overflow-scrolling : touch;
        overflow-x:scroll;overflow-y:hidden;
            padding-left:0 ;

    }
    li{
        list-style: none;
    display: inline-block;
    width: 240px;
    }
    .scrollx::-webkit-scrollbar{
	display: none;
}

</style>