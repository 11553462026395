import Vue from 'vue'
import Vuex from 'vuex'
import VueXAlong from 'vuex-along' //导入插件


Vue.use(Vuex)


const store = new Vuex.Store({
    state:{
        currentProductMenu:{
            id:Number,
            name:String
        },
        currentSolutionMenu:{
            id:Number,
            name:String
        },
        currentServiceMenu:{
            id:Number,
            name:String
        },
        currentAboutMenu:{
            path:String
        },
        company:{
            name:String,
            introduction:String,
            address:String,
            telephone:String,
            email:String,
            img:String,
            isUse:false,
        },
        productList:[],
        solutionList:[],
       
    },
    mutations:{
        setProductValue(state,e){
            state.currentProductMenu.id = e.id;
            state.currentProductMenu.name = e.name;
        },
        setSolutionValue(state,e){
            state.currentSolutionMenu.id = e.id;
            state.currentSolutionMenu.name = e.name;
        },
        setServiceValue(state,e){
            state.currentServiceMenu.id = e.id;
            state.currentServiceMenu.name = e.name;
        },
        setAboutValue(state,e){
            state.currentAboutMenu.path =e.path;
        },
        setCompany(state,e){
            state.company = e
        },
        setProductList(state,e){
            state.productList = e
        },
        setSolutionList(state,e){
            state.solutionList = e
        }
        
        
    },
    plugins: [VueXAlong()],
    getters:{
        getCompany :state => state.company,
        getProductList :state => state.productList,
        getSolutionList :state => state.solutionList,
    }
})


export default store