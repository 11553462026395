<template>
        <div class="in1">
            <div class="container1">
                <div class="tit">
                    <h2 class="wow slideInDown">产品中心</h2>
                    <p class="wow fadeInLeft">我们专注于音频会议系统、教学音频系统，主要产品有：DSP数字音频处理器，Dante阵列麦克风，Dante音箱，数模转换接口等。</p>
                </div>
                <div class="in1-con clearfix">

                   
                    <div class="in1-con1 clearfix">
                        
                        <div class="in1-left pin wow slideInLeft" style="visibility: visible; animation-name: slideInLeft;" >
                            <router-link :to="'/product/category/'+menuList[0].id">
                                <div class="in1-txt">
                                    <h3>{{ menuList[0].name }}</h3>
                                    <p> {{ menuList[0].description }}</p>
                                    <span>查看更多</span>
                                </div>
                                <div class="in1-img">
                                    <!-- <img src="../imgs/2022042710144547.png" alt=""> -->
                                    <img :src="$host +'/files/'+menuList[0].imgPath" alt="" @click="toSendProductTitle(menuList[0])">
                                </div>
                            </router-link>
                        </div>
                        <div class="in1-right pin wow slideInRight" style="visibility: visible; animation-name: slideInRight;">
                            <router-link :to="'/product/category/'+menuList[1].id">
                                <div class="in1-txt">
                                    <h3>{{ menuList[1].name }}</h3>
                                    <p> {{ menuList[1].description }}</p>
                                    <span>查看更多</span>
                                </div>
                                <div class="in1-img">
                                    <!-- <img src="../imgs/2022042710144547.png" alt=""> -->
                                    <img :src="$host +'/files/'+menuList[1].imgPath" alt="" @click="toSendProductTitle(menuList[1])">
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="in1-con1 in1-con2 clearfix">
                        <div class="in1-left bro wow slideInLeft" style="visibility: visible; animation-name: slideInLeft;">
                            <router-link :to="'/product/category/'+menuList[2].id">
                                <div class="in1-txt">
                                    <h3>{{ menuList[2].name }}</h3>
                                    <p> {{ menuList[2].description }}</p>
                                    <span>查看更多</span>
                                </div>
                                <div class="in1-img">
                                    <!-- <img src="../imgs/2022042710144547.png" alt=""> -->
                                    <img :src="$host +'/files/'+menuList[2].imgPath" alt="" @click="toSendProductTitle(menuList[2])">
                                </div>
                            </router-link>
                        </div>
                        <div class="in1-right gra wow slideInRight" style="visibility: visible; animation-name: slideInRight;">
                            <router-link :to="'/product/category/'+menuList[3].id" >
                                <div class="in1-txt">
                                    <h3>{{ menuList[3].name }}</h3>
                                    <p> {{ menuList[3].description }}</p>
                                    <span>查看更多</span>
                                </div>
                                <div class="in1-img">
                                    <!-- <img src="../imgs/2022042710144547.png" alt=""> -->
                                    <img :src="$host +'/files/'+menuList[3].imgPath" alt="" @click="toSendProductTitle(menuList[3])">
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <!-- <a href="" class="view-more">查看更多</a> -->
                    <router-link :to="'/product/category/'+menuList[0].id"  class="view-more">查看更多</router-link>
                </div>
               
            </div>
            
        </div>
        
</template>
<script>
import axios from 'axios';
import { add } from 'lodash';
export default {
    data() {
        return {
            menuList:[],
        }
    },
    mounted(){
        new this.$wow.WOW().init();
    },
    computed:{
        currentProductMenu(){
            
            return this.$store.state.currentProductMenu
        }
    },
    created() {
        axios.get("/category/isuse2").then(res => {
            if(res.data.data.items.length>0){
                const categorys = res.data.data.items;
            this.menuList = categorys.slice(0,4);
            }
           
        })
    },
    methods: {
        toSendProductTitle(e){
            this.$store.commit("setProductValue",e)
        // bus.$emit("menuMsg",id,name)
      },
    },

}
</script>
<style scoped>
</style>