<template>
    <div class="about-con" style="background-color: #f5f5f5;">
       <div class="about-one">
            <div class="main">
                <div class="about-tit">
                    <h2>
                        公司介绍
                    </h2>
                    <p>COMPANY PROFILE</p>
                </div>
                <div class="about1-con clearfix">
                    <div class="about1-left">
                        <h3>{{company.name}}</h3>
                        <p class="out">
                            {{company.introduction }}
                        </p>
                    </div>
                    <div class="about1-right">
                        <img :src="$host+'/files/'+img" alt="">
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            img:"",
            company:{}
        }
    },
    created() {
        if( this.$store.getters.getCompany.isUse){
            this.company = this.$store.getters.getCompany;
     
            this.getImgAddress(this.company.img)
        }
      
    },
    methods: {
        getImgAddress(ids){
            axios.get("/attach/getByIds",{
                params:{
                    ids
                }
            }).then(res => {
                if(res.data.data.items.length>0){
                     this.img = (res.data.data.items)[0].address;
                }
            })
        }
    },
}
</script>