import { render, staticRenderFns } from "./Bottom.vue?vue&type=template&id=2479a04c&scoped=true&"
import script from "./Bottom.vue?vue&type=script&lang=js&"
export * from "./Bottom.vue?vue&type=script&lang=js&"
import style0 from "./Bottom.vue?vue&type=style&index=0&id=2479a04c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2479a04c",
  null
  
)

export default component.exports