<template>
     <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000;margin-top: 110px;"
        fade
        indicators
        
    >
        <b-carousel-slide v-for="backimg in backimgs" :key="backimg.id" 
                :img-src="$host+'/files/'+backimg.address">
        </b-carousel-slide>
    </b-carousel>
</template>
<script>
import axios from 'axios'
  export default {
        data() {
            return {
                backimgs:[
                    // {
                    //     id:1,
                    //     path:require("@/assets/images/home1.jpg")
                    // },
                    // {
                    //     id:2,
                    //     path:require("@/assets/images/home2.jpg")
                    // },
                    // {
                    //     id:3,
                    //     path:require("@/assets/images/home3.jpg")
                    // },
                    // {
                    //     id:4,
                    //     path:require("@/assets/images/home4.jpg")
                    // },
                    // {
                    //     id:5,
                    //     path:require("@/assets/images/home5.jpg")
                    // },
                    // {
                    //     id:6,
                    //     path:require("@/assets/images/home6.jpg")
                    // },
                ]
            }
        },

        created() {
            this.fetchData();
        },
        // mounted() {
        //     this.fetchData();
        // },

        methods: {
            
            fetchData(){
                axios.get("/dashboard/id",{
                    params:{
                        id:1
                    }
                }
                ).then( res => {

                    const data = res.data.data.items.lunbo;
                    axios.get("/attach/getByIds",{
                        params:{
                            ids:data
                        }
                    }).then( request => {
                        const arr = request.data.data.items;
                        this.backimgs = arr
                        console.log(this.backimgs)
                    })
                })
            }
        },
    }
</script>