<template>
  
  <div class="bottom">
    <div class="bottom1">
        
            <div class="box1 clearfix" style="display: flex; justify-content: center;">
                <ul>
                    <li>
                        <!-- <router-link :to="'/product/category/'+productList[0].id">产品中心</router-link> -->
                        产品中心
                    </li>
                    <li v-for="product in productList" :key="product.id"
                            @click="selectMenu(product);">
                        <!-- <a :href="menu.href">{{ menu.name }}</a> -->
                        <router-link :to="'/product/category/'+product.id">{{ product.name }}</router-link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <!-- <router-link :to="'/solution/category/'+solustionList[0].id">解决方案</router-link> -->
                        解决方案
                    </li>
                    <li v-for="menu in solustionList" :key="menu.id" :class="{on:menu.id==firstId}"
                            @click="selectSoluMenu(menu)">
                        <!-- <a :href="menu.href">{{ menu.name }}</a> -->
                        <router-link :to="'/solution/category/'+menu.id">{{ menu.name }}</router-link>
                    </li>
                </ul>
                <!-- <ul>
                    <li>
                        <router-link :to="'/product/typeId/'+firstId" @click.native="changeData()">项目案例</router-link>
                    </li>
                    <li v-for="menu in programList" :key="menu.id" :class="{on:menu.id==firstId}"
                            @click="selectMenu(menu)">
                        
                        <router-link :to="'/product/typeId/'+menu.id">{{ menu.name }}</router-link>
                    </li>
                </ul> -->
                <ul>
                    <li>
                        <!-- <router-link :to="'/service/'+supportList[0].path">服务支持</router-link> -->
                        服务支持
                    </li>
                    <li v-for="menu in supportList" :key="menu.id" :class="{on:menu.id==firstId}"
                            @click="selectServiceMenu(menu)">
                        <!-- <a :href="menu.href">{{ menu.name }}</a> -->
                        <router-link :to="'/service/'+menu.path">{{ menu.name }}</router-link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <!-- <router-link :to="'/about/'+aboutList[0].path">关于我们</router-link> -->
                        关于我们
                    </li>
                    <li v-for="menu in aboutList" :key="menu.id" :class="{on:menu.id==firstId}"
                            @click="selectAboutMenu(menu)">
                        <!-- <a :href="menu.href">{{ menu.name }}</a> -->
                        <router-link :to="'/about/'+menu.path">{{ menu.name }}</router-link>
                    </li>
                </ul>
                
                
                <div class="bottom-lx" style="float: right">
                    <p class="bottom-phone">
                        {{ company.telephone }}
                    </p>
                    <p>
                        地址：{{ company.address }}
                    </p>
                    <p>
                        电话：{{ company.telephone }}
                    </p>
                    <p>
                        邮箱：{{ company.email }}
                    </p>
                </div>
                
            </div>
            <!-- <div>
                <p>Copyright © MASTER科技技术有限公司</p>
                <a href="http://beian.miit.gov.cn/">辽ICP备2023001910号</a>
            </div> -->
        </div>
    
  </div>
</template>

<script>

import axios from 'axios';
import bus from '@/bus.js'
export default {
  name: 'mainfooter',
  components: {
  },
  data(){
    return{
        firstName:String,
        firstId:Number,
        currentId:Number,
        currentName:String,
        company:{},
        productList:[],
        solustionList:[
            // {
            //     id:1,
            //     name:'会议音频系统解决方案'
            // },
            // {
            //     id:2,
            //     name:'教学音频系统解决方案'
            // },
            // {
            //     id:3,
            //     name:'便携扩声系统解决方案'
            // },
        ],
        programList:[
            {
                id:1,
                name:'会议系统'
            },
            {
                id:2,
                name:'教育行业'
            },
            {
                id:3,
                name:'商用行业'
            },
        ],
        supportList:[
            {
                id:1,
                path:'sales',
                name:'售后服务'
            },
            {
                id:2,
                path:'question',
                name:'常见问题'
            },
            {
                id:3,
                path:'video',
                name:'视频中心'
            },
            {
                id:4,
                path:'download',
                name:'资料下载'
            },
        ],
        aboutList:[
            {
                id:1,
                path:'company',
                name:'公司简介'
            },
            {
                id:2,
                path:'connect',
                name:"联系方式"
            }
        ],
        connectList:[
            {
                id:1,
                name:'联系方式'
            },
            {
                id:2,
                name:'诚聘英才'
            },
           
        ],
        
    }
  },
  created() {
        this.productList  = this.$store.getters.getProductList;
        this.solustionList = this.$store.getters.getSolutionList;
        if(this.$store.getters.getCompany.id)
            this.company = this.$store.getters.getCompany;
    },
  methods: {
        selectMenu(e){
            this.$store.commit("setProductValue",e)
        },
        selectSoluMenu(e){
            this.$store.commit("setSolutionValue",e)
        },
        selectServiceMenu(e){
            this.$store.commit("setServiceValue",e)
        },
        selectAboutMenu(e){
            this.$store.commit("setAboutValue",e)
        },
        changeData(){
           const data = this.productList[0];
           this.currentId = data.id;
           this.currentName = data.name;
        },
        

    },
}
</script>
<style>

</style>


