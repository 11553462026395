<template>
   

    <div class="productxq-con">
        <div class="productxq-one">
            <div class="main">
                <div class="fang pro_left">
                    <div id="preview" class="spec-preview">
                        <span class="jqzoom">
                            <img :src="$host+'/files/'+this.cuurentImg" alt="">
                        </span>
                    </div>
                    <div class="canshu">
                        <div class="h1">
                            {{ product.name }}
                        </div>
                        <p>
                            {{ product.description }}
                        </p>
                        <div class="clearfix canshu-share">

                        </div>
                        <div class="canshu-line"></div>
                        <div class="spec-scroll">
                            <div class="items">
                                <ul id="itemsUl">
                                    <li class="" v-for="(item, index) in imgList" :key="index" >
                                        <img :src="$host+'/files/'+item.address" alt="" @mouseenter="onEnterTd(index)">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="productxq-menu">
                    <ul class="clearfix" style="padding-left: 0px;">
                        <li v-for="(menu,index) in menuList" :key="menu.id" :class="{on:menu.id==currentMenuId}"  @click="selectMenu(menu)" >
                            <a href="javascript:;" v-if="index==1"  @click="handleClickProductPara">{{ menu.name }}</a>
                            <a href="javascript:;" v-else-if="index==2"  @click="handleClickDownload">{{ menu.name }}</a>
                            <a href="javascript:;" v-else >{{ menu.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="productxq-detall" id="productxq-detall" >
                    <div class="productxq-det" :style="{display:currentMenuId==1?'block':'none'}" >
                        <div class="productxq-det1">
                            <div class="solution-tit1 clearfix">
                                <h3>
                                    产品介绍
                                </h3>
                                <span>PRODUCT DESCRIPTION</span>
                            </div>
                            <!-- <p>DBOX44-H 网络音频接口箱实现网络数字音频信号与模拟音频信号的互转，4×4是模拟多媒体系统进行数字化升级、扩展的绝佳之选。</p> -->
                            <p>{{ product.description }}</p>
                            <div class="productxq-img1 clearfix">
                                <div class="img1">
                                    <p style="text-align: center;" v-for="(item, index) in imgList" :key="index">
                                        <img :src="$host+'/files/'+item.address" alt="" @mouseenter="onEnterTd(index)">
                                        <!-- <img src="../imgs/2020060811361420.png" alt="DBOX.png" title="DBOX.png"> -->
                                    </p>
                                    <p>
                                        <br>
                                    </p>
                                    <p>
                                        <br>
                                    </p>
                                    <!-- <p style="text-align: center;">
                                        <img src="../imgs/2019111814296207.png" alt="DBOX软件-33.png">
                                    </p> -->
                                </div>
                            </div>
                        </div>
                        <div class="productxq-det1" style="display: block;">
                            <div class="solution-tit1 clearfix">
                                <h3>
                                    产品特点
                                </h3>
                                <span>
                                    PROCUCT FEATURES
                                </span>
                            </div>
                            <p v-for="(chara ,index) in charaList" :key="index">
                                {{ chara }}
                            </p>
                                <!-- <p>采用DSP技术的高性能专业音箱处理器</p>
                                <p>采用32位浮点DSP、24位专业级AD/DA转换器、96K采样频率</p>  
                                <p>设备提供网口、RS-485、RS-232和GPIO端口实现完善的连接管理功能</p>  
                                <p>输入输出分别提供高低通和7段PEQ</p>  
                                <p>输入每通道延时长达1000ms，输出每通道延时长达1000ms</p>  
                                <p>设备提供信号发生器：正弦、粉噪、白噪</p>  
                                <p>输入提供返馈抑制、噪声门、压缩限幅器功能，输出提供压缩限幅器功能</p>   -->
                          
                        </div>
                        <!-- <div class="productxq-det1" style="display: block;">
                            <div class="solution-tit1 clearfix">
                                <h3>
                                    控制系统
                                </h3>
                                <span>
                                    CONTROL SYSTEM
                                </span>
                            </div>
                            <p>DBOX软件系统，带给用户更直观的操作感受和更智能的音频处理体验。</p>
                            <div class="productxq-img2 clearfix" style="text-align: center;">
                                <img src="../imgs/2019111814301306.png" alt="DBOX软件-34.png">
                            </div>
                        </div> -->
                        
                    </div>
                    <ProductPara :msg="currentMenuId"  ref="productPara" ></ProductPara>
                </div>
                <Download :msg="currentMenuId"  ref="download" ></Download>
            </div>
        </div>
    </div>

</template>
<script>
import ProductPara from './productParameters.vue'
import Download from './download.vue'
import axios from 'axios'
export default {
        name:'',
        components: {
            ProductPara,
            Download
        },
        data(){
            return{

                cuurentImg:'',

                

                charaList:[],
                imgList:[],
                product:{},

                currentMenuId:1,
                menuList:[
                    {
                        id:1,
                        name:'产品描述'
                    },
                    {
                        id:2,
                        name:'产品参数'
                    },
                    // {
                    //     id:3,
                    //     name:'应用场景'
                    // },
                    {
                        id:4,
                        name:'资料下载'
                    },
                ]
                    
            }
            
        },

        created() {
           if(this.$route.params.id){
                const id = this.$route.params.id;
                this.fetchdata(id);
           }
        },

        methods: {

            handleClickDownload(){
                this.$refs.download.fetchdata(this.product.attachment)
            },
            handleClickProductPara(){
                this.$refs.productPara.setValue(this.product.parameters)
            },

            selectMenu(e){
           this.currentMenuId = e.id;
         },
         fetchdata(val){
            axios.get("/product/id2",
                {
                    params:{
                        id:val
                    }
                }
            ).then(res=> {
                const data =  res.data.data.items;
               
                const chara =data.characteristic;
                let arr =[];
                if(chara.endsWith("\n")){
                    arr = chara.split("\n");
                    arr.pop()
                }else{
                    arr = chara.split("\n");
                }
                
                this.charaList = arr;

                this.getImg(data.imgPath)

                this.product = data;
            })
         },

         getImg(ids){
            axios.get("/attach/getByIds",{
                params:{
                    ids
                }
            }).then(res => {
                const data = res.data.data.items;
                this.imgList = data;
                this.cuurentImg = this.imgList[0].address
            })
         },

         onEnterTd(index){
            this.cuurentImg = this.imgList[index].address;
         }
        },
    }
</script>
<style scoped>
.fontSize{
    font-size: 35px !important;
}
@media (max-width:640px){
    .spec-preview {
        width:100%
    }
    .canshu{
        float: left;
        width: 100%;
        margin-left: 0;
    }
    .productxq-menu li{
        padding:0 10px 10px;
    }
}

</style>