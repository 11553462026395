<template>
    <div class="productxq-det" :style="{display:msg==2?'block':'none'}">
                            <div class="productxq-det1">
                                <div class="solution-tit1 clearfix">
                                    <h3>
                                        产品参数
                                    </h3>
                                    <span>PRODUCT PARAMETERS</span>
                                </div>
                                <table width="1026">
                                    <tbody id="tbody1" v-html="comments">
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div>
</template>
<script>
export default {
    props:{msg:Number},
    data(){
        return{
                parameters:{"DSP芯片":"","信号处理":"32-bit fixed/floating-point DSP","音频系统延迟":"＜1ms","数模转换":"24-bit","采样率":" 96kHz","输入":"","输入接口":" 3路XLR平衡输入，阻抗10kΩ","最大输入电平":" +18dBu","输入电平编组":"-70dB- +12dB，可反向","动态范围 ":"120dB","输入通道延时":" 0-1000ms每通道","频率响应":"20Hz-20kHz","总谐波失真":"＜0.0035% THD+D","功能":" 压缩限幅器","滤波器":" HPF、LPF、7段PEQs","信号发生器":"正弦、粉噪、白噪","输出":"","输出接口":" 6路XLR平衡输入，阻抗100Ω","最大输出电平":" +18dBu","输出电平编组":"-70dB- +12dB，可反向","输出通道延时":" 0-1000ms每通道","滤波器 ":" HPF、LPF、7段PEQs","系统":"","连接":"网口、RS-485、RS-232和GPIO端口","尺寸":"482×44.5×265mm","净重":" 3.4kg"},
            // parameters:{"信号处理":" 32-bit fixed/floating-point DSP 300MHz","音频系统延迟":"＜1ms","数模转换":"24-bit","采样率":"48kHz","输入通道":" 8路平衡输入，Mic/line level","音频接口":"3.81mm凤凰插，12-pin","输入阻抗":" 11.5KΩ","最大输入电平":"12dBu/line,-9dBu/Mic","幻象电源":"+48VDC，10mA，每通道配置","输出通道":" 8路平衡输出，line level","输出阻抗":" 150Ω","频率响应":"20Hz-20kHz（+-0.5dB）/Line，20Hz-20kHz（+-1.5dB）/Mic","THD+N":"-90dB（@12dBu,1kHz,A-wt）/Line，       -86dB（@-7dBu,1kHz,A-wt）/Mic","信噪比":" 105dB（@12dBu,1kHz,A-wt）/Line，95dB（@-7dBu,1kHz,A-wt）/Mic","上位机接口":" RJ45，免驱","RS232/RS485":" 凤凰座","GPIO口":" 凤凰座，调用机器存档（可定制功能）","指示灯":" Power,link,+48V,输入输出音频信号","供电范围":"AC100V---240V 50/60Hz","尺寸":"482×44.5×265mm","净重":" 2.8kg"},
            comments:``
        }
        
    },

   

    updated() {
      
    },


    methods: {
        setValue(para){
            //const a =  eval('(' + para + ')')
            const array = para.split("|||")
            var data=`` ;
            let i=0;
            for (const key in array) {
                const element = array[key];
                if(element.indexOf("：")==-1){
                    if(i%2==1){
                            data += `<td width="171" valign="top" style="word-break: break-all;"></td>`;
                            data += `<td width="342" valign="top" style="word-break: break-all;"></td>`;
                            data += `</tr>`;
                        }
                        data += ` <tr class="firstRow">` +
                                `<td class="fontSize">`+element+`</td>` + 
                                `</tr>`;
                        i=0;
                }else{
                    const arr = element.split("：");
                    i++;
                        if(i%2==1){
                            data+=`<tr class="firstRow">`;
                        }
                        data += `<td width="171" valign="top" style="word-break: break-all;">`+arr[0]+`</td>`;
                        let newElement = arr[1];
                        if(element.indexOf("&")!=-1){
                            newElement = element.replace(/&/g,'<br/>')
                        }
                        data += `<td width="342" valign="top" style="word-break: break-all;">`+newElement+`</td>`;
                        
                        if(i%2==0){
                            data+= `</tr>`;
                        }
                }
                // if (Object.hasOwnProperty.call(a, key)) {
                //     const element = a[key];
                //     if(element==""){
                //         if(i%2==1){
                //             data += `<td width="171" valign="top" style="word-break: break-all;"></td>`;
                //             data += `<td width="342" valign="top" style="word-break: break-all;"></td>`;
                //             data += `</tr>`;
                //         }
                //         data += ` <tr class="firstRow">` +
                //                 `<td class="fontSize">`+key+`</td>` + 
                //                 `</tr>`;
                //         i=0;
                //     }else{
                //         i++;
                //         if(i%2==1){
                //             data+=`<tr class="firstRow">`;
                //         }
                //         data += `<td width="171" valign="top" style="word-break: break-all;">`+key+`</td>`;
                //         let newElement = element;
                //         if(element.indexOf("&")!=-1){
                //             newElement = element.replace(/&/g,'<br/>')
                //         }
                //         data += `<td width="342" valign="top" style="word-break: break-all;">`+newElement+`</td>`;
                        
                //         if(i%2==0){
                //             data+= `</tr>`;
                //         }
                //     }
                // }
            }
            if(i%2==1){
                data += `<td width="171" valign="top" style="word-break: break-all;"></td>`;
                data += `<td width="342" valign="top" style="word-break: break-all;"></td>`;
                data += `</tr>`;
            }
            this.comments=data;
            }
        },
    
}
</script>
<style scoped>
#table1 td:first-child, .productxq-detall table td:first-child, .productxq-detall table td:nth-child(3){
    color:black;
    background: white;
}
</style>