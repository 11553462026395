<template>
    
    <div class="product paddbo">
        <div class="in-top1 banner">
            <div class="img">
                <img src="../imgs/productTitle.jpg" alt="">
            </div>
            <div class="img1">
                <img src="../imgs/productTitle.jpg" alt="">
            </div>
            <div class=" banner-text">
                <h1>产品中心</h1>
                <p>Product Center</p>
            </div>
            <div class=" banner-txt">
                <div class="main">
                    <p>
                        <span>
                            当前位置：
                        </span>
                        <a href="/">首页</a>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <router-link :to="'/product/category/'+currentProductMenu.id">产品中心</router-link>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <a href="javascript:;">{{ currentProductMenu.name }}</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="menu">
            <div class="main clearfix">
                <ul class="clearfix scrollx" id="menu" >
                    <li v-for="menu in menuList" :key="menu.id" :class="{on:menu.id==currentProductMenu.id}"
                            @click="selectMenu(menu)">
                        <router-link :to="'/product/category/'+menu.id">{{ menu.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
       
    </div>

</template>
<script>

import axios from 'axios';
import bus from '@/bus.js'
export default {

    data(){
        return{
            menuList:[]
        }
    },
    computed:{
        currentProductMenu(){
            
            return this.$store.state.currentProductMenu
        }
    },
    created(){
        
        axios.get("/category/isuse").then(res => {
            if(res.data.data){
                const categorys = res.data.data.items;
                this.menuList = categorys;
            }
            
        })

        
    },

    methods: {
        selectMenu(e){
            this.$store.commit("setProductValue",e)
        },
       
    },
}
</script>
<style scoped>
    ul{
        display: block;
        overflow: hidden;
        white-space:nowrap;
        -webkit-overflow-scrolling : touch;
        overflow-x:scroll;overflow-y:hidden;
        padding-left:0 ;

    }
    li{
        list-style: none;
        display: inline-block;
        width: 130px;
    }
    .scrollx::-webkit-scrollbar{
	display: none;
}

</style>