import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/assets/css/public.css'
import router from './router'
import './style.scss'
import '@/assets/css/styles.css'
import 'font-awesome/css/font-awesome.min.css'
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import store from './store'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'


// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VXETable)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$host='http://47.94.248.118:8081'

axios.defaults.baseURL='http://47.94.248.118:8081'
// Vue.prototype.$host='http://localhost:8082'

// axios.defaults.baseURL='http://localhost:8082'

Vue.prototype.$wow = wow





new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
