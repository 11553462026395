import VueRouter from 'vue-router'
import Vue from 'vue'
import Home from '../views/Home/index.vue'
import Product from '../views/Product/index.vue'
import ProductList from '../views/Product/compnents/productList.vue'
import Details from '../views/Product/compnents/details.vue'
import Solution from '../views/solution/index.vue'
import SolutionList from '../views/solution/compnents/solutionList.vue'
import SoluDetails from '../views/solution/compnents/details.vue'
import Service from '../views/Servise/index.vue'
import AfterSales from '../views/Servise/compnents/afterSales.vue'
import Question from '../views/Servise/compnents/question.vue'
import Video from '../views/Servise/compnents/video.vue'
import Download from '../views/Servise/compnents/download.vue'
import About from '../views/About/index.vue'
import Company from '../views/About/compnents/company.vue'
import Connect from '../views/About/compnents/connect.vue'
Vue.use(VueRouter)


var router=new VueRouter({

    routes:[
        {
            path:'/',
            redirect:'/home'
        },
        {
            path:'/home',
            component:Home,
        },
        {
            path:'/product',
            component:Product,
            children:[
                {
                    path:'category/:id',
                    component:ProductList,
                    children:[
                       
                    ] 
                },
                {
                    path:'productDetail/:id',
                    component:Details,
                },
            ]
        },
        {
            path:'/solution',
            component:Solution,
            children:[
                {
                    path:'category/:id',
                    component:SolutionList,
                    children:[
                       
                    ] 
                },
                {
                    path:'solutionDetail/:id',
                    component:SoluDetails,
                },
            ]
        },
        {
            path:'/about',
            component:About,
            children:[
                {
                    path:'company',
                    component:Company,
                    children:[
                       
                    ] 
                },
                {
                    path:'connect',
                    component:Connect,
                },
            ]
        },
        {
            path:'/service',
            component:Service,
            children:[
                {
                    path:'sales',
                    component:AfterSales,
                    children:[
                       
                    ] 
                },
                {
                    path:'question',
                    component:Question,
                    children:[
                       
                    ] 
                },
                {
                    path:'video',
                    component:Video,
                    children:[
                       
                    ] 
                },
                {
                    path:'download',
                    component:Download,
                    children:[
                       
                    ] 
                },
                // {
                //     path:'solutionDetail/:id',
                //     component:SoluDetails,
                // },
            ]
        },
        
        
    ],

    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      }
 
    
})



router.beforeEach((to, from, next) => {
    if(to.path===from.path){
        console.log('路由相等啦')
       return

    } else {

        next()
    }
})



export default router