<template>
    <div class="problem paddbo">
        <div class="problem-list clearfix">
            <div class="main">
                <div role="tablist" class="proclem-box" id="box">
                    <el-collapse accordion @change="handleChange">
                        <el-collapse-item v-for="item in items" :key="item.id"  :name="item.id" @change="handleChange(item)">
                            
                            <span class="collapse-title" slot="title" :class="{red:item.id ==currentId}">{{ item.question }}</span>
                            <div class="hide" style="overflow: hidden; display: block;">
                                {{ item.answer }}
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <el-pagination
                        :page-size="tablePage.pageSize"
                        :pager-count="7"
                        :current-page="tablePage.currentPage"
                        layout="->, prev, pager, next"
                        :total="tablePage.totalResult"
                        @current-change ="handlePageChange">
                    </el-pagination>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            currentId :0,
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
              },
            items:[
                {
                    id:1,
                    question:'忘记登录密码怎么办？',
                    answer:'在网孔旁边有一个小孔，标注了R，为复位键，长按5秒以上有效，复位后设备将恢复出场设置，密码将恢复到默认密码。'
                },
                {
                    id:2,
                    question:'忘记登录密码怎么办？',
                    answer:'在网孔旁边有一个小孔，标注了R，为复位键，长按5秒以上有效，复位后设备将恢复出场设置，密码将恢复到默认密码。'
                },
            ]
        }
    },

    created() {
        this.fetchData(this.tablePage.currentPage);
    },

    methods:{
        handleChange(e){
            this.currentId =e
        },
        handlePageChange (e) {
            // console.log(e)
            // this.tablePage.currentPage =e;
            //   this.fetchData()
            this.tablePage.currentPage =e;
            console.log(this.tablePage.currentPage)
            this.fetchData(e);
            },

        fetchData(e) {
             this.listLoading = true
             
             axios.get("/question/all",{
                params:{
                    pageNum:e,
                    pageSize:this.tablePage.pageSize
                }
             }).then(response => {
                
              this.items = response.data.data.items.records
              this.tablePage.currentPage = response.data.data.items.current
              this.tablePage.pageSize = response.data.data.items.size
              this.tablePage.totalResult = response.data.data.items.total
              
             this.listLoading = false
            })
        },
    }
}
</script>
<style scoped>
.collapse-title{
    font-size: 18px;
}
.red{
    color:#4587e8;
}
.proclem-box >>> .el-collapse-item__header {
    height: 80px ;
}
</style>