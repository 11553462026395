<template>
     <div class="in2">
            <div class="in2all">
                <div class="in2-box1" id="in2-box1"
                
                >
                    <div class="container1">
                        <div class="tit">
                            <h2 class="wow slideInDown" style="visibility: visible; animation-name: slideInDown;">
                                应用领域
                            </h2>
                            <p class="wow fadeInLeft" style="visibility: visible; animation-name: fadeInLeft;">
                               智慧音视频系统解决方案提供商，为用户提供会议扩声系统、手拉手会议系统、远程视频会议系统、
                                <br>
                                教学扩声、互动教学等的专业音频产品和整体解决方案
                            </p>
                        </div>
                        <div class="in2-con wow fadeInDown" style="visibility: visible; animation-name: fadeInDown;">
                            <ul class="clearfix" id="in2-menu">
                                <!-- <li v-for="area in areas" :key="area.id" v-on:mouseover="addClassload(area.id)" v-on:mouseout="removeClassload(area.id)"
                                    :class="{on:classenable&&area.id==current}">
                                    <a href="/article/jyhy68.html">
                                        <div class="in2-img">
                                            <img :src="getUrl(area.path)" :alt="area.name">
                                        </div>
                                        <div class="in2-txt">
                                            <h4>{{area.name}}</h4>
                                            <p>{{ area.description }}</p>
                                        </div>
                                    </a>
                                </li> -->
                                <li v-for="(item,index) in menuList" :key="item.id" :class="{'on':item.active}"  @mouseover="handleHover(item)" @mouseout="handleOut(item)" 
                                    >
                                    
                                    <router-link :to="'/solution/category/'+item.id" >
                                        <div class="in2-img" @click="toSendSolutionTitle(item)">
                                            <!-- <img :src="'../imgs/in2-img'+(index+1)+'.png'" alt="教学音频系统"> -->
                                            <img :src=getUrl(index) alt="教学音频系统">
                                        </div>
                                        <div class="in2-txt" @click="toSendSolutionTitle(item)">
                                            <h4>{{ item.name }}</h4>
                                            <p>{{ item.description }}</p>
                                        </div>
                                    </router-link>
                                    
                                </li>
                                <!-- <li>
                                    <a href="/article/jyhy68.html">
                                        <div class="in2-img">
                                            <img src="../imgs/in2-img2.png" alt="教学音频系统">
                                        </div>
                                        <div class="in2-txt">
                                            <h4>会议音频系统</h4>
                                            <p>系统设备精简，布线简单，低延时，信号无损传输，稳定可靠且易拓展，为您创造舒适的会议音频体验环境。</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="/article/jyhy68.html">
                                        <div class="in2-img">
                                            <img src="../imgs/in2-img3.png" alt="教学音频系统">
                                        </div>
                                        <div class="in2-txt">
                                            <h4>智能便携扩声系统</h4>
                                            <p>主要街头艺术、家庭聚会、校园派对、婚礼、会议室、酒吧、练歌房、音乐教室等场合。</p>
                                        </div>
                                    </a>
                                </li> -->
                            </ul>
                            <!-- <a href="/page/lxfs36.html" class="in2-get">免费获取方案</a> -->
                            <router-link :to="'/solution/category/'+currentSolutionMenu.id" class="in2-get">免费获取方案</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="in2_appcon">
                <div class="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-ios">
                    <div class="swiper-wrapper" style="transform: translate3d(-414px, 0px, 0px); transition-duration: 0ms;">
                        <div class="swiper-slide swiper-slide-duplicate swiper-slide-prev app-img"  data-swiper-slide-index="2">
                            <div class="in2app-txt">
                                <div class="tit">
                                    <h2>
                                        应用领域
                                    </h2>
                                    <p>声菲特-智慧音视频系统解决方案提供商，为用户提供会议扩声系统、手拉手会议系统、远程视频会议系统、教学扩声、互动教学等的专业音频产品和整体解决方案。</p>
                                </div>
                                <div class="in2-img">
                                    <img src="../imgs/in2-img3.png" alt="智能便携扩声系统">
                                </div>
                                <div class="in2-txt">
                                    <h4>智能便携扩声系统</h4>
                                    <p>主要街头艺术、家庭聚会、校园派对、婚礼、会议室、酒吧、练歌房、音乐教室等场合。</p>
                                    <a href="" class="in2-get">免费获取方案</a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide swiper-slide-duplicate swiper-slide-prev app-img"  data-swiper-slide-index="2">
                            <div class="in2app-txt">
                                <div class="tit">
                                    <h2>
                                        应用领域
                                    </h2>
                                    <p>声菲特-智慧音视频系统解决方案提供商，为用户提供会议扩声系统、手拉手会议系统、远程视频会议系统、教学扩声、互动教学等的专业音频产品和整体解决方案。</p>
                                </div>
                                <div class="in2-img">
                                    <img src="../imgs/in2-img1.png" alt="教学音频系统">
                                </div>
                                <div class="in2-txt">
                                    <h4>教学音频系统</h4>
                                    <p>本地会议室这一类现场应用最关键的问题是保证声场均匀无反馈，对声压级的稳定有着特殊的要求</p>
                                    <a href="" class="in2-get">免费获取方案</a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide swiper-slide-duplicate swiper-slide-prev app-img"  data-swiper-slide-index="2">
                            <div class="in2app-txt">
                                <div class="tit">
                                    <h2>
                                        应用领域
                                    </h2>
                                    <p>声菲特-智慧音视频系统解决方案提供商，为用户提供会议扩声系统、手拉手会议系统、远程视频会议系统、教学扩声、互动教学等的专业音频产品和整体解决方案。</p>
                                </div>
                                <div class="in2-img">
                                    <img src="../imgs/in2-img2.png" alt="会议音频系统">
                                </div>
                                <div class="in2-txt">
                                    <h4>会议音频系统</h4>
                                    <p>系统设备精简，布线简单，低延时，信号无损传输，稳定可靠且易拓展，为您创造舒适的会议音频体验环境。</p>
                                    <a href="" class="in2-get">免费获取方案</a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide swiper-slide-duplicate swiper-slide-prev app-img"  data-swiper-slide-index="2">
                            <div class="in2app-txt">
                                <div class="tit">
                                    <h2>
                                        应用领域
                                    </h2>
                                    <p>声菲特-智慧音视频系统解决方案提供商，为用户提供会议扩声系统、手拉手会议系统、远程视频会议系统、教学扩声、互动教学等的专业音频产品和整体解决方案。</p>
                                </div>
                                <div class="in2-img">
                                    <img src="../imgs/in2-img3.png" alt="智能便携扩声系统">
                                </div>
                                <div class="in2-txt">
                                    <h4>智能便携扩声系统</h4>
                                    <p>主要街头艺术、家庭聚会、校园派对、婚礼、会议室、酒吧、练歌房、音乐教室等场合。</p>
                                    <a href="" class="in2-get">免费获取方案</a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide swiper-slide-duplicate swiper-slide-prev app-img"  data-swiper-slide-index="2">
                            <div class="in2app-txt">
                                <div class="tit">
                                    <h2>
                                        应用领域
                                    </h2>
                                    <p>声菲特-智慧音视频系统解决方案提供商，为用户提供会议扩声系统、手拉手会议系统、远程视频会议系统、教学扩声、互动教学等的专业音频产品和整体解决方案。</p>
                                </div>
                                <div class="in2-img">
                                    <img src="../imgs/in2-img1.png" alt="教学音频系统">
                                </div>
                                <div class="in2-txt">
                                    <h4>教学音频系统</h4>
                                    <p>本地会议室这一类现场应用最关键的问题是保证声场均匀无反馈，对声压级的稳定有着特殊的要求</p>
                                    <a href="" class="in2-get">免费获取方案</a>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                            <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" 
                            role="button" aria-label="Go to slide 1"></span>
                            <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span>
                            <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span>
                        </div>
                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            isShow:false,
            menuList:[],
            isHover:false
        }
    },
    created() {
        axios.get("/solutionCategory/all").then(res => {
            const categorys = res.data.data.items;
            this.menuList = categorys;
            console.log(this.menuList)
        })
    },
    mounted(){
        new this.$wow.WOW().init();
    },
    computed:{
        currentSolutionMenu(){
            
            return this.$store.state.currentSolutionMenu
        }
    },
    methods: {
        getUrl(index){
            return require("../imgs/in2-img"+(index+1)+".png")
        },
        handleHover(item){
            var _this=this;
        this.$nextTick(function () {
          this.menuList.forEach(function (item) {
            _this.$set(item,'active',false);
          });
          this.$set(item,'active',true);
        });

        },
        handleOut(item){
          this.$set(item,'active',false);
        },
        toSendSolutionTitle(e){
            console.log(e)
            this.$store.commit("setSolutionValue",e)
        // bus.$emit("menuMsg",id,name)
      },
        
    },
}
</script>
<style>
li{
    list-style-type: none;
}
</style>