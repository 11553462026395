<template>
    <div class="productxq-det" :style="{display:msg==4?'block':'none'}">
        <div class="productxq-det1">
            <div class="solution-tit1 clearfix">
                <h3>
                    相关下载
                </h3>
                <span>RELATED DOWNLOAD</span>
            </div>
            <br>
            <div class="download-box" id="box">
                <div class="download-box1">
                    <table>
                        <tbody>
                            <tr>
                                <th>名称</th>
                                <th>大小</th>
                                <th>上传时间</th>
                                <th>下载</th>
                            </tr>
                            <tr v-for="(item, index) in fileList" :key="index">
                                <td>{{item.originalName}}</td>
                                <td>{{ item.size }}</td>
                                <td>{{ item.createDate }}</td>
                                <td>
                                    <a :href="$host+'/attach/download/'+item.id" target="_blank"></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props:{
        msg:Number,
        data:''
    },
    data(){
        return{
            fileList:[]
            
        }
    },
    updated() {
       
    },


    mounted() {
        this.fetchdata(this.data)
    },

    methods:{
        
        fetchdata(ids){
            axios.get("/attach/getByIds",{
                params:{
                    ids
                }
            }).then(res => {
                const data = res.data.data.items;
                this.fileList = data;
                
            })
         },

         changeData(){
         }
        
    }
    
}
</script>