<template>
    
    <div class="product paddbo">
        <div class="in-top1 banner">
            <div class="img">
                <img src="../imgs/30270655.jpg" alt="" height="446px">
            </div>
            <div class="img1">
                <img src="../imgs/30270655.jpg" alt="">
            </div>
            <div class=" banner-text">
                <h1>关于我们</h1>
                <p>About Us</p>
            </div>
            <div class=" banner-txt">
                <div class="main">
                    <p>
                        <span>
                            当前位置：
                        </span>
                        <a href="/">首页</a>
                        <em>&nbsp;&gt;&nbsp;</em>
                        <router-link to="/about/">关于我们</router-link>
                        
                    </p>
                </div>
            </div>
        </div>
        <div class="menu">
            <div class="main clearfix">
                <ul class="clearfix scrollx" id="menu" >
                    <li v-for="menu in menuList" :key="menu.id" :class="{on:menu.path==currentAboutMenu.path}"
                            @click="selectMenu(menu)">
                        <router-link :to="'/about/'+menu.path">{{ menu.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
       
    </div>

</template>
<script>

import axios from 'axios';
import bus from '@/bus.js'
export default {

    data(){
        return{
            menuList:[
                {
                    id:1,
                    path:'company',
                    name:'公司简介'
                },
                {
                    id:2,
                    path:'connect',
                    name:"联系方式"
                }
            ]
        }
    },
    computed:{
        currentAboutMenu(){
            
            return this.$store.state.currentAboutMenu
        }
    },
    created(){
        
        // axios.get("http://localhost:8081/category/isuse").then(res => {

        //     const categorys = res.data.data.items;
        //     this.menuList = categorys;
        // })

        
        
    },

    methods: {
        selectMenu(e){
            this.$store.commit("setAboutValue",e)
        },
       
    },
}
</script>
<style scoped>
    ul{
        display: block;
        overflow: hidden;
        white-space:nowrap;
        -webkit-overflow-scrolling : touch;
        overflow-x:scroll;overflow-y:hidden;
            padding-left:0 ;

    }
    li{
        list-style: none;
    display: inline-block;
    width: 130px;
    }
    .scrollx::-webkit-scrollbar{
	display: none;
}

</style>