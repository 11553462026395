<template>
    <div>
        <Title></Title>
        <router-view :key="$route.path"></router-view>
    </div>
</template>
<script>
import Title from './compnents/title.vue'
export default {

    components:{
        Title
    },

    mounted() {
        window.scrollTo
    }
}
</script>