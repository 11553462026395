<template>
    <div class="font">
        <p>Copyright © MASTAR科技技术有限公司 丨<a href="http://beian.miit.gov.cn/">辽ICP备2023001910号</a></p>
    </div>
</template>
<script>
export default {
    name:'bottom'
}
</script>
<style scoped>
.font {
    display: flex;
    justify-content: center;
    width: 100%;
    
}
@media screen and (max-width: 670px) {
    .font{
        font-size: 0.5em;
    }
}
</style>