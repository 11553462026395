<template>
<div id="app">
  <Navbar></Navbar>
  <!-- <Home /> -->
  <router-view></router-view>
  <Footer></Footer>
  <Bottom></Bottom>
</div>

</template>

<script>
import Navbar from './components/Navbar.vue'
import { WOW } from 'wowjs'
import Footer from './components/Footer.vue'
import Bottom from './components/Bottom.vue'
export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Bottom
  },
  watch: {
    $route(to, from) {
      this.$nextTick(() => {
        new WOW({ mobile: true, live: true, scrollContainer: '.layout-content' }).init()
      })
    }
  },
}
</script>

<style>
#app{
  background: #fff;
}
</style>
