<template>
    <div class="about-con" style="background-color: #f5f5f5;">
       <div class="about-one">
            <div class="main">
                <div class="about-tit">
                    <h2>
                        联系方式
                    </h2>
                    <p>Contact Information</p>
                </div>
                <div class="about1-con clearfix">
                    <div class="about1-left1">
                        <!-- <img src="../imgs/company.jpg" alt=""> -->
                        <img :src="$host+'/files/'+img" alt="">
                    </div>
                    <div class="about1-right1">
                        <h3>{{ company.name }}</h3>
                        <p class="out">{{company.address}}</p>
                        <!-- <p class="out">番禺展厅地址：禺山西路363号联邦工业城内</p> -->
                        <p class="out"><i class="el-icon-phone"></i>{{company.telephone}}</p>
                        <p class="out"> {{company.email}}</p>
                        <!-- <p class="out">邮编：510800</p> -->
                    </div>
                </div>
            </div>
       </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {

    data() {
        return {
            img:'',
            company:{
                
            }
        }
    },
    created() {
        if(this.$store.getters.getCompany.isUse){
            this.company = this.$store.getters.getCompany;
            this.getImgAddress(this.company.img)
        }
        
    },
    methods: {
        getImgAddress(ids){
            console.log(ids)
            axios.get("/attach/getByIds",{
                params:{
                    ids
                }
            }).then(res => {
                console.log(res)
                if(res.data.data.items.length>0){
                     this.img = (res.data.data.items)[0].address;
                }
            })
        }
    }
}
</script>
<style>
.about1-left1{
    float:left;
    width: 45%;
    overflow: hidden;
}
.about1-left1 img {
width: 100%;
transition: ease .5s;
}

.about1-left1:hover img {
transform: scale(1.04);
}
.about1-right1{
    float:right;
    width: 47%;
}

.about1-right1 h3 {
font-size: 24px;
line-height: 30px;
color: #333;
position: relative;
padding-bottom: 20px;
margin-bottom: 20px;
}

.about1-right1 h3::before {
content: "";
position: absolute;
width: 50px;
height: 2px;
background: #1d65b0;
bottom: 0;
left: 0;
}

.about1-right1 p {
font-size: 16px;
line-height: 30px;
color: #666;
max-height: 150px;
padding-right: 5px;
text-align: justify;
}
</style>