<template>
    <div class="product-con">
        <div class="main">
            <!-- <el-row>
  <el-col :span="7"  v-for="(product, index) in products" :key="product.id" :offset="index%3 > 0 ? 1 : 0">
    <el-card :body-style="{ padding: '0px' }">
     
      <router-link :to="'/product/productDetail/'+product.id" >
                        <div class="product-img">
                            <img :src="$host +'/files/'+ product.imgPath" alt="">
                            
                        </div>
                        <div class="product-txt">
                            <div class="clearfix">
                                <h3>
                                    {{ product.name }}
                                </h3>
                            </div>
                            <br>
                            <p>{{ product.description }}</p>
                            <span>查看更多</span>
                        </div>
                    </router-link>  
    </el-card>
  </el-col>
</el-row> -->
            <ul class="clearfix">
                <li v-for="product in products" :key="product.id">
                    <router-link :to="'/product/productDetail/'+product.id" >
                        <div class="product-img">
                            <img :src="$host +'/files/'+ product.imgPath" alt="">
                            
                        </div>
                        <div class="product-txt">
                            <div class="clearfix">
                                <h3>
                                    {{ product.name }}
                                </h3>
                            </div>
                            <br>
                            <p>{{ product.description }}</p>
                            <span>查看更多</span>
                        </div>
                    </router-link>
                </li>
            </ul>
            <div class="page">

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import bus from '@/bus.js'
export default{
    data(){
        return{
            currentId:Number,
            currentName:String,

            products:[
                // {
                //     id:1,
                //     name:'TIGER系列数字音频处理器',
                //     description:'TIGER 44、84、88、1208、1212、1616',
                //     imgPath:'DSP1.jpg'
                // },
                // {
                //     id:2,
                //     name:'TIGER系列数字音频处理器',
                //     description:'TIGER 44、84、88、1208、1212、1616',
                //     imgPath:'DSP2.jpg'
                // },
                // {
                //     id:3,
                //     name:'TIGER系列数字音频处理器',
                //     description:'TIGER 44、84、88、1208、1212、1616',
                //     imgPath:'DSP3.jpg'
                // },
                // {
                //     id:4,
                //     name:'TIGER系列数字音频处理器',
                //     description:'TIGER 44、84、88、1208、1212、1616',
                //     imgPath:'DSP1.jpg'
                // }
            ]
        }
    },

    created(){
       
        if(this.$route.params.id){
            const data =this.$route.params.id;
            this.getById(data);
        }
    },

    methods: {
        
        toSendTitle(id,name){
            bus.$emit("menuMsg",id,name)
        },
       
        getById(id){
                       
            axios.get("/product/category2",{
                params:{
                    id
                }
            }).then(res => {
                 this.products = res.data.data.items;
               
            })
        }
    },
}
</script>
<style scoped>
    @media (mix-width:640px){
    .product-con{
    padding-top: 0px;
    }
}
</style>