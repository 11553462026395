<template>
    <div>
        <Slider></Slider>
        <PorductCenter></PorductCenter>
        <Application></Application>
        <!-- <Program></Program>
        <News></News> -->
    </div>
   
    
</template>
<script>
  import Slider from './compnents/slider.vue'
  import PorductCenter from './compnents/productCenter.vue'
  import Application from './compnents/application.vue'
  // import News from './compnents/news.vue'
  export default {
    components:{
        Slider,
        PorductCenter,
        Application,
        // Program,
        // News
    }
  }
</script>