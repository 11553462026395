<template>
    <div class="solutionxq-con">
        <div class="solution-tit">
            本地视频会议系统
        </div>
        <div class="solutionxq-one">
            <div class="main">
                <div class="solution-tit1 clearfix">
                    <h3>
                        方案介绍
                    </h3>
                    <span>
                        PROGRWM INTRODUCTION
                    </span>
                </div>
                <p>
                    本地视频会议系统适合多方通话、本地会议等，采用S-TRACK数字音频处理器，可兼容市场上多种场景的视讯终端，可实现语音、视频互动，本地扩音等需求；采用AFC、AEC、ANC三大算法能够轻松解决会议室扩声中让人头痛的声学反馈、声学回声、声学噪声等问题。音质更加清晰，让会议更加高效。
                </p>
                <p style="line-height: 2em; text-align: center;">
                    <br>
                </p>
                <p>
                    <br>
                </p>
            </div>
        </div>
        <div class="solutionxq-two">
            <div class="main">
                <div class="solution-tit1 clearfix">
                    <div>
                        <h3>
                            方案特色/优势
                        </h3>
                        <span>
                            PROGRAM FEATURES / ADVANTAGES
                        </span>
                    </div>
                    <p>
                        <span>
                            <br>
                        </span>
                    </p>
                    <p style="font-size: 36px; color: rgb(0, 112, 192);">

                        网络化 · 易操作 · 易管理
                    </p>
                    <p>
                        <br>
                    </p>
                </div>
            </div>
        </div>
        <div class="solutionxq-three">
            <div class="main">
                <div class="solution-tit1 clearfix">
                    <h3>
                        方案拓扑图
                    </h3>
                    <p>
                        <span>
                            SCHEME TOPOLOGY
                        </span>
                    </p>
                    <p>
                        <span>
                            <br>
                        </span>
                    </p>
                    <p>
                        <span>
                            <br>
                        </span>
                    </p>
                    <p style="text-align: center;">
                        <img src="../imgs/2019120417287850.jpg" alt="">
                    </p>
                </div>
            </div>
        </div>
        <div class="solutionxq-four">
            <div class="main">
                <div class="solution-tit1 clearfix">
                    <h3>
                        产品组成
                    </h3>
                    <p>
                        <span>
                            PRODUCT COMPOSITION
                        </span>
                    </p>
                    <p>
                        <span>
                            <br>
                        </span>
                    </p>
                    <p>
                        <span>
                            <br>
                        </span>
                    </p>
                    <p style="text-align: center;">
                        <img src="../imgs/2020033014545819.jpg" alt="">
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isshow:false
        }
    },
}
    
</script>
<style>
.hidden{
    display: none;
}
</style>